import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo/seo";

const SecurityPage = () => {
  return (
    <Layout>
      <Seo title="Security" pathname="/security" />
      <main>Security Page</main>
    </Layout>
  );
};

export default SecurityPage;
